import { useEffect, useMemo } from 'react'
import { isEqual } from 'lodash'

import { useTableReturnType } from '@src/components/TableV2/hooks'
import { FilterByInterface } from '@src/interfaces/data'
import { toIdAndName } from '@src/utils/toIdAndName'
import { UseTimelineFilterReturnType } from '../TimelineFilter/useTimelineFilter'
import { UseHeatmapFiltersReturnType } from './useHeatmapFilters'
import { ItemsToAnalyse } from '../common'

export const getDateRangeFilterParams = (
  dateFrom: string,
  dateTo: string,
): FilterByInterface[] => {
  return [
    {
      columnName: 'from_date',
      filters: [toIdAndName(dateFrom)],
      nonInheritable: true,
      nonResettable: true,
    },
    {
      columnName: 'to_date',
      filters: [toIdAndName(dateTo)],
      nonInheritable: true,
      nonResettable: true,
    },
  ]
}

const getHeatmapFilterParams = (
  groupByKey: string | undefined,
  groupByValue: string | undefined,
  scopeFilter: FilterByInterface[] | undefined,
): FilterByInterface[] => {
  if (!groupByKey || !groupByValue) {
    return []
  }
  const groupByValueOverriddenByScope = scopeFilter?.find(
    filterItem => filterItem.columnName === groupByKey,
  )
  return [
    {
      columnName: 'group_by',
      filters: [toIdAndName(groupByKey)],
      nonInheritable: true,
      nonResettable: true,
    },
    {
      columnName: groupByKey,
      filters: groupByValueOverriddenByScope?.filters || [toIdAndName(groupByValue)],
      nonInheritable: true,
      nonResettable: true,
    },
  ]
}

const getActiveNonTableFilters = (
  allActiveFilters: FilterByInterface[],
  itemsToAnalyse: ItemsToAnalyse | undefined,
) => {
  switch (itemsToAnalyse) {
    case 'categories': {
      const categoriesTableFilters = ['id']
      return allActiveFilters.filter(
        filterItem => !categoriesTableFilters.includes(filterItem.columnName),
      )
    }
    case 'questions': {
      const questionsTableFilters = ['id', 'driver', 'type']
      return allActiveFilters.filter(
        filterItem => !questionsTableFilters.includes(filterItem.columnName),
      )
    }
    default:
      return []
  }
}

const validateTimelineFilter = (timelineFilter: UseTimelineFilterReturnType) => {
  return timelineFilter.dateFrom != null && timelineFilter.dateTo != null
}

const validateHeatmapFilters = (
  heatmapFilters: UseHeatmapFiltersReturnType | undefined,
) => {
  return Boolean(heatmapFilters?.groupBy.paramKey && heatmapFilters?.groupBy.paramValue)
}

export const useApplyNonTableFilters = <T>({
  disable,
  table,
  timelineFilter,
  heatmapFilters,
  scopeFilters,
  itemsToAnalyse,
  onRefresh,
  isHeatmap,
}: {
  disable: boolean
  table: useTableReturnType<T>
  timelineFilter: UseTimelineFilterReturnType
  heatmapFilters?: UseHeatmapFiltersReturnType
  scopeFilters?: FilterByInterface[]
  itemsToAnalyse?: ItemsToAnalyse
  onRefresh?: VoidFunction
  isHeatmap?: boolean
}) => {
  const { dateFrom, dateTo } = timelineFilter
  const groupByKey = heatmapFilters?.groupBy.paramKey
  const groupByValue = heatmapFilters?.groupBy.paramValue

  const invalidFilters =
    !validateTimelineFilter(timelineFilter) ||
    (isHeatmap && !validateHeatmapFilters(heatmapFilters))

  const nonTableFilters = useMemo(() => {
    const dateRangeFilterParams = getDateRangeFilterParams(dateFrom, dateTo)
    const restFilterParams = isHeatmap
      ? getHeatmapFilterParams(groupByKey, groupByValue, scopeFilters)
      : scopeFilters || []
    return [...dateRangeFilterParams, ...restFilterParams]
  }, [dateFrom, dateTo, groupByKey, groupByValue]) // scopeFilters are not on the deps list since they shouldn't change

  useEffect(() => {
    table.resetFiltersAndSorting(nonTableFilters)
    onRefresh?.()
  }, [itemsToAnalyse])

  const activeNonTableFilters = getActiveNonTableFilters(table.filterBy, itemsToAnalyse)

  useEffect(() => {
    const isDisabled = disable || invalidFilters
    const nothingChanged =
      isEqual(nonTableFilters, activeNonTableFilters) || !activeNonTableFilters.length

    if (isDisabled || nothingChanged) {
      return
    }
    table.resetFiltersAndSorting(nonTableFilters)
    onRefresh?.()
  }, [disable, invalidFilters, nonTableFilters, activeNonTableFilters])
}
